import React, { useEffect, useState } from 'react'
import AOS from 'aos';
import Banner from './Banner';
import { NavLink, useParams } from 'react-router-dom'

function ServiceDetails() {
    const pageTitle = 'Service Details'
    document.title = pageTitle

    const prm = useParams()
    const [abtData, setAbtdata] = useState([]);
    const [services, setServices] = useState([]);

    useEffect(() => {
        AOS.init({easing: 'ease-in-out-sine',once:true})


        async function servicesApi() {
            let response = await fetch(
                'https://skelectronics.com.au/admin/api/services',
                {
                    method: 'GET',
                }
            );
            let data = await response.json(); // Extracting data as a JSON Object from the response
            setServices(data)
            //console.warn(services.data)
        }
        servicesApi();
       
      }, [])
      useEffect(() => {
        AOS.init({ easing: 'ease-in-out-sine', once: true })
        async function abtApi() {
            let response = await fetch(
                'https://skelectronics.com.au/admin/api/Pages',
                {
                    method: 'GET',
                }
            );
            let data = await response.json(); // Extracting data as a JSON Object from the response
            setAbtdata(data)
        }
        abtApi();
    }, [])
    
      

  return (
    <>
    {/* <Banner title={pageTitle}  /> */}
    {
        abtData.data ? abtData.data.map((v, i) => {
            if (v.id === "12") {
                return (<Banner title={pageTitle} imgPath={v.banner} />)
            } else {
                return (
                    <></>
                )
            }
        }) : 'Loading...'
    }
    <section className="sdPage">
    <div className="container">
        <div className="row">
            <div className="col-md-4">
                <aside>
                   <h3>All Services</h3>
                    <ul>
                    {
                 services.data && services.data.map((v,i)=>{
                        return(
                            <li><NavLink activeClassName="active" to={'/services/'+v.slug}>{v.title}</NavLink></li>
                        )
                 })   
            }
                    </ul>
                </aside>
            </div>
            <div className="col-md-8">
                <main> 
                    {
                        services.data && services.data.map((v,i)=>{
                           
                            if(v.slug === prm.id){
                                return(
                                    <>
                    <figure className="mb-4">
                        <img src={v.image} alt={v.title} />
                    </figure>
                    <figcaption>
                        <h1>{v.title}</h1>
                        <div className="text">
                        <div dangerouslySetInnerHTML={{__html: v.description}} />
                        </div>
                    </figcaption>
                                </>
                                )
                                
                            }else{
                                return(
                                    <></>
                                )
                            }
                        })
                    }
                </main>
            </div>
        </div>
    </div>
</section>
    </>
  )
}

export default ServiceDetails