import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'

function Footer () {
  const [services, setServices] = useState([])
  const [gernal, setGernal] = useState([])
  const [headerData, setHeaderData] = useState([])

  useEffect(() => {
    async function servicesApi () {
      let response = await fetch(
        'https://skelectronics.com.au/admin/api/services',
        {
          method: 'GET'
        }
      )
      let data = await response.json() // Extracting data as a JSON Object from the response
      setServices(data)
      //console.warn(services.data)
    }
    servicesApi()

    async function generalDataApi () {
      let response = await fetch(
        'https://skelectronics.com.au/admin/api/GeneralData',
        {
          method: 'GET'
        }
      )
      let data = await response.json() // Extracting data as a JSON Object from the response
      setGernal(data)
      //console.warn(gernal.data)
    }
    generalDataApi()
    async function getResponse () {
      let response = await fetch(
        'https://skelectronics.com.au/admin/api/GeneralData',
        {
          method: 'GET'
        }
      )
      let data = await response.json() // Extracting data as a JSON Object from the response
      setHeaderData(data)
    }
    getResponse()
  }, [])

  return (
    <footer>
      <div className='f2'>
        <div className='container'>
          <div className='flex'>
            <div className='b1'>
              <h2>ABOUT US</h2>
              {gernal.data
                ? gernal.data.map((v, i) => {
                    if (v.id === '6') {
                      return (
                        <>
                          <div
                            dangerouslySetInnerHTML={{ __html: v.description }}
                          />
                        </>
                      )
                    } else {
                      return <></>
                    }
                  })
                : 'Loading...'}
              <div className='qr'>
                <ul>
                  {gernal.data
                    ? gernal.data.map((v, i) => {
                        if (v.id === '23') {
                          return (
                            <figcaption>
                              <img className='tni' src={v.image} alt='' />
                            </figcaption>
                          )
                        } else {
                          return <></>
                        }
                      })
                    : 'Loading...'}
                </ul>
              </div>
            </div>
            <div className='b2'>
              <h2>Quick Link</h2>
              <ul>
                <li className='active'>
                  <Link to='/'>Home</Link>
                </li>
                <li>
                  <Link to='/about'>About Us</Link>
                </li>
                <li>
                  <Link to='/services'>Services</Link>
                </li>
                <li>
                  <Link to='/contact'>Contact Us</Link>
                </li>
              </ul>
            </div>
            <div className='b2'>
              <h2>Services</h2>
              <ul>
                {services.data
                  ? services.data.map((v, i) => {
                      return (
                        <li>
                          <NavLink
                            activeClassName='active'
                            to={'/services/' + v.slug}
                          >
                            {v.title}
                          </NavLink>
                        </li>
                      )
                    })
                  : 'Loading...'}
              </ul>
            </div>
            <div className='b4'>
              <h2>Contact us</h2>
              <ul>
                {headerData.data
                  ? headerData.data.map((v, i) => {
                      if (v.id === '3') {
                        return (
                          <>
                            <li>
                              <div className='icon'>
                                <i className='fas fa-phone-alt'></i>
                              </div>
                              <div className='text'>
                                <b>Call anytime</b>
                                <Link to='tel:1300 349 255'>
                                  1300 349 255 ( 1300 FIX ALL )
                                </Link>
                                <Link to={'tel:' + v.description}>
                                  {' '}
                                  {v.description}
                                </Link>
                              </div>
                              <label for=''></label>
                            </li>
                          </>
                        )
                      } else if (v.id === '2') {
                        return (
                          <>
                            <li>
                              <div className='icon'>
                                <i className='fas fa-envelope'></i>
                              </div>
                              <div className='text'>
                                <b>Email Us</b>
                                <Link to={'mailto:' + v.description}>
                                  {v.description}
                                </Link>
                              </div>
                            </li>
                          </>
                        )
                      } else if (v.id === '20') {
                        return (
                          <>
                            <li>
                              <div className='icon'>
                                <i className='fas fa-map-marker-alt'></i>
                              </div>
                              <div className='text'>
                                <b>Address</b>
                                <Link to={v.description} target='_blank'>
                                {' '}
                                {v.title}
                              </Link>
                              </div>
                            </li>
                          </>
                        )
                      } else {
                        return <></>
                      }
                    })
                  : 'Loading...'}
              </ul>
              <div className='social'>
                <ul>
                  {gernal.data
                    ? gernal.data.map((v, i) => {
                        if (v.id === '7') {
                          return (
                            <li>
                              <Link to={v.description}>
                                <i className='fab fa-facebook-f'></i>
                              </Link>
                            </li>
                          )
                        }
                        if (v.id === '8') {
                          return (
                            <li>
                              <Link to={v.description}>
                                <i className='fab fa-twitter'></i>
                              </Link>
                            </li>
                          )
                        }
                        if (v.id === '9') {
                          return (
                            <li>
                              <Link to={v.description}>
                                <i className='fab fa-instagram'></i>
                              </Link>
                            </li>
                          )
                        }
                        if (v.id === '10') {
                          return (
                            <li>
                              <Link to={v.description}>
                                <i className='fab fa-linkedin-in'></i>
                              </Link>
                            </li>
                          )
                        } else {
                          return <></>
                        }
                      })
                    : 'Loading...'}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='f3'>
        <div className='container'>
          <div className='flex jcc'>
            <p>
              © COPYRIGHT 2023 | <Link to='/'>SK Electronics</Link> ALL RIGHTS
              RESERVED
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
