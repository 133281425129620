import React, { useEffect, useState } from 'react'
import Banner from './Banner'
import OwlCarousel from 'react-owl-carousel2';
import AOS from 'aos';

function About() {
    const pageTitle = 'About Us'
    document.title = pageTitle

    const [abtData, setAbtdata] = useState([]);
    const [gernalData, setGernalData] = useState([]);
    const [clients, setClients] = useState([]);

    const optionsPartners = {
        items:3,
		loop:true,
		autoplay:true,
		responsiveClass:true,
		slideSpeed : 200,
		paginationSpeed : 800,
    rewindSpeed : 1000,
		dots:false,
		responsive:{
			1080:{items:6},
			768:{items:3},
			600:{items:2},
			425:{items:1},
			360:{items:1},
			30:{items:1}
			
		}
    }

    useEffect(() => {
        AOS.init({easing: 'ease-in-out-sine',once:true})

        async function abtApi() {
            let response = await fetch(
                'https://skelectronics.com.au/admin/api/Pages',
                {
                    method: 'GET',
                }
            );
            let data = await response.json(); // Extracting data as a JSON Object from the response
            setAbtdata(data)
        }
        abtApi();
        async function gernalData() {
            let response = await fetch(
                'https://skelectronics.com.au/admin/api/GeneralData',
                {
                    method: 'GET',
                }
            );
            let data = await response.json(); // Extracting data as a JSON Object from the response
            setGernalData(data)
        }
        gernalData();

        async function clientsApi() {
            let response = await fetch(
                'https://skelectronics.com.au/admin/api/Clients',
                {
                    method: 'GET',
                }
            );
            let data = await response.json(); // Extracting data as a JSON Object from the response
            setClients(data)
        }
        clientsApi();
        
        
      }, [])

  return (
    <>
    {
        abtData.data ? abtData.data.map((v,i)=>{
            if(v.id === "11"){
                return(<Banner title={v.title} imgPath={v.banner} />)
            }else{
                return(
                    <></>
                )
            }
        }) : 'Loading...'
    }
    
<section className="abt">
    <div className="container">
    {
                        abtData.data ? abtData.data.map((v,i)=>{
                            if(v.id === "11"){
                                return(
        <div className="row aic">
            <div className="col-md-7">
                <div className="rightContent">
                    
                                <>
                                <div dangerouslySetInnerHTML={{__html: v.description}} />
                                
                                </>
                                
                </div>
            </div>
            <div className="col-md-5">
                <aside>
                    <figure data-aos="fade-left" data-aos-duration="700" data-aos-delay="300"><img src={v.image} alt="" /></figure>
                    <img src="https://skelectronics.com.au/assets/images/more/abt2.jpg" alt='about' className="img2" />
                </aside>
            </div>
        </div>
        )
    }else{
        return(
            <></>
        )
    }
}) : 'Loading...'
}
    </div>
</section>
<section className="expertise">
    <div className="container">
       <h1>OUR EXPERTISE</h1>
        <div className="row">
        {
         gernalData.data ? gernalData.data.map((v,i)=>{
            if(v.id === "15"){ 
                return(
                    <>
                        
                        <div className="col-lg-6">
                <div className="imgBox"><img src={v.image} alt={v.image_alt} /></div>
            </div>
            <div className="col-lg-6">
            <aside dangerouslySetInnerHTML={{__html: v.description}}></aside>
            </div>
                    </>
                )
            }else{
                return(
                    <></>
                )
            }
         })   : 'Loading...'
        }
        
        </div>
    </div>
</section>
<section className="partners">
    <div className="container">
        <h1>We also welcome trade inquires & repairs for..</h1>
        {/* <div className="owl-carousel partners-carousel owl-theme"> */}
        <OwlCarousel options={optionsPartners}>
        {
                 clients.data ? clients.data.map((v,i)=>{
                        return(
                            <div className="item" key={i}>
                                <figure>
                                    <img src={v.image} alt={v.image_alt} />
                                </figure>
                            </div>
                        )
                 })   : 'Loading...'
            }
            
            </OwlCarousel>
    </div>
</section>
    </>
  )
}

export default About