import React, { useEffect, useState } from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import Banner from './Banner'
import { Link } from 'react-router-dom'
import AOS from 'aos'


function Contact() {
    const pageTitle = 'Contact Us'
    document.title = pageTitle
    const [abtData, setAbtdata] = useState([]);
    const [headerData, setHeaderData] = useState([]);
    const [color,setColor]=useState('#000');
    const [tit,setTit]=useState('Disabled');
const [verified,setVerified]=useState(false)
const [dis,setDis]=useState("not-allowed")

    useEffect(() => {
        AOS.init({easing: 'ease-in-out-sine',once:true})

        async function abtApi() {
            let response = await fetch(
                'https://skelectronics.com.au/admin/api/Pages',
                {
                    method: 'GET',
                }
            );
            let data = await response.json(); // Extracting data as a JSON Object from the response
            setAbtdata(data)
        }
        abtApi();
        async function getResponse() {
            let response = await fetch(
                'https://skelectronics.com.au/admin/api/GeneralData',
                {
                    method: 'GET',
                }
            );
            let data = await response.json(); // Extracting data as a JSON Object from the response
            setHeaderData(data)
        }
        getResponse()
        
        
      }, []);
     
      function onChange(value) {
        setVerified(true);
        setColor("#001e4d");
        setTit("Send");
        setDis("pointer");
      }

  return (
    <>
    {
        abtData.data ? abtData.data.map((v,i)=>{
            if(v.id === "13"){
                return(<Banner title={v.title} imgPath={v.banner} />)
            }else{
                return(
                    <></>
                )
            }
        }) : 'Loading...'
    }
    <section class="contact contactPage">
    <div class="container">
        <div class="headingBox">
            <h6>CONTACT US</h6>
            <h1>Feel Free to Write</h1>
        </div>
        <div class="contactBox">
            <div class="flex">
                <div class="formBox">
                    <h4>Request a quote</h4>
                    <form action="./mail.php" method='post'>
                        <div class="row">
                            <div class="col-md-6">
                                <label for="">Your Name</label>
                                <input type="text" name="name" required />
                            </div>
                            <div class="col-md-6">
                                <label for="">Email address</label>
                                <input type="email" name="email" minLength={5} required />
                            </div>
                            <div class="col-md-6">
                                <label for="">Phone number</label>
                                <input type="number" name="phone" minLength={5} required />
                            </div>
                            <div class="col-md-6">
                                <label for="">Subject</label>
                                <input type="text" name="subject" minLength={5} required />
                            </div>
                            <div class="col-md-12">
                                <label for="">Message</label>
                                <textarea name="msg" required minLength={5}></textarea>
                            </div>
                            <div className='col-md-12'>
                             <ReCAPTCHA
                                sitekey="6LeBhZQpAAAAAHwT8yTl5qlMqDYeAyRfABNCb1aV"
                                // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                onChange={onChange}
                              />
                            </div>
                            <div class="col-md-12">
                                <input type="submit" value="send"  name="submitBtn" style={{background:color,cursor:dis}} title={tit} disabled={!verified} />
                            </div>
                        </div>
                    </form>
                </div>
                <div class="rightBox">
                    <div class="upper">
                        <ul>
                            {
                                headerData.data ? headerData.data.map((v, i) => {
                                        if (v.id === "3") {
                                            return (
                                                <>
                                                    <li>
                                                        <label for="">Call anytime</label>
                                                        <Link to="tel:1300 349 255">1300 349 255 ( 1300 FIX ALL )</Link>
                                                        <Link to={"tel:" + v.description}> {v.description}</Link>
                                                    </li>
                                                </>
                                            )
                                        } 
                                        else if (v.id === "2") {
                                                return (
                                                    <>
                                                        <li>
                                                            <label for="">Send email</label>
                                                            <Link to={"mailto:" + v.description}> {v.description}</Link>
                                                        </li>
                                                    </>
                                                )
                                            } else if (v.id === "20") {
                                                return (
                                                    <>
                                                        <li>
                                                            <label for="">Address</label>
                                                            <Link to={v.description} target='_blank'> {v.title}</Link>
                                                        </li>
                                                    </>
                                                )
                                            } else {
                                                return (
                                                    <></>
                                                )
                                            }
                                }) : 'Loading...'
                            }
                        </ul>
                    </div>
                    <div class="bottom">
                        <div class="social">
                            <ul>
                                <li><Link to="#"><i class="fab fa-facebook-f"></i></Link></li>
                                <li><Link to="#"><i class="fab fa-twitter"></i></Link></li>
                                <li><Link to="#"><i class="fab fa-instagram"></i></Link></li>
                                <li><Link to="#"><i class="fab fa-pinterest-p"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="map p-0">
    <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3140.363163246936!2d145.24464257565475!3d-38.085209771908765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad60e18118e1257%3A0xcc61e514cc0c47d8!2su13%2F37%20Industrial%20Cct%2C%20Cranbourne%20West%20VIC%203977%2C%20Australia!5e0!3m2!1sen!2sin!4v1686644772928!5m2!1sen!2sin" width="600" height="450" allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</section>
    </>
  )
}

export default Contact