import React, { useEffect, useState } from 'react'

function Test() {
    
    
    const [abtData, setAbtdata] = useState([]);

    useEffect(() => {
        

        
        async function getResponse() {
            const response = await fetch(
                'https://skelectronics.com.au/admin/api/Pages',
                {
                    method: 'GET',
                }
            );
            const data = await response.json(); // Extracting data as a JSON Object from the response
            
            setAbtdata(data)
            //console.warn(abtData)
        }
        getResponse()
      }, [])
      
      //console.warn('data',)
      console.warn(abtData)


  return (
    <>
    <div className="container">
    <h1 className="text-center my-5 py-5">Test</h1>
    {
        abtData.data && abtData.data.map((v,i)=>{
            return(
                <li key={i}>{v.title}</li>
            )
        })
    }
    </div>
    </>
  )
}

export default Test