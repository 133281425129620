import React, { useEffect, useState } from 'react'
import Banner from './Banner'
import AOS from 'aos';
import { useParams } from 'react-router-dom';
import axios from 'axios';
// import { all } from 'axios';


function Search() {
    const pageTitle = 'FAQ'
    document.title = pageTitle

    const param = useParams();
    // console.log(param.id);
    
    const [data, setData] = useState([]);
    const [services, setServices] = useState([]);
    const [faqs, setFaqs] = useState([]);
    // console.log(data);


    useEffect(() => {
        AOS.init({ easing: 'ease-in-out-sine', once: true })

        async function faqsApi() {
            await axios.get('https://skelectronics.com.au/admin/api/Pages').then((res) => {
                // console.log(res);
                setData(res.data)
            })
        }
        faqsApi();

        // get dynamic data of services section
        async function servicesApi() {
            let response = await fetch(
                'https://skelectronics.com.au/admin/api/services',
                {
                    method: 'GET',
                }
            );
            let data = await response.json(); // Extracting data as a JSON Object from the response
            setServices(data.data)
        }
        servicesApi();
        async function faqApi() {
            let response = await fetch(
                'https://skelectronics.com.au/admin/api/faqs',
                {
                    method: 'GET',
                }
            );
            let data = await response.json(); // Extracting data as a JSON Object from the response
            setFaqs(data.data)
        }
        faqApi();
    }, [])

    // filter services data with search value

    // let AllData = [];
    let AllData = services.filter((item) => {
        if (item.shortdescription.includes(param.id) || item.title.includes(param.id)) {
            return item;
        } else {
            return null;
        }

    })
    // filter faq data with search value
    let faqAllData = faqs.filter((item) => {
        if (item.question.includes(param.id) || item.answer.includes(param.id)) {
            return item;
        } else {
            return null;
        }

    })

    return (
        <>
            {
                data.data ? data.data.map((v, i) => {
                    if (v.id === "11") {
                        return (<Banner title="Search" imgPath={v.banner} />)
                    } else {
                        return (
                            <></>
                        )
                    }
                }) : 'Loading...'
            }

            <section class="searchPage">
                <div class="container">
                    {
                        faqAllData.length || AllData.length ?
                            <>
                                <div class="searchBox">
                                    {faqAllData.length ?
                                        <>
                                            <h1>Faq</h1>
                                            <hr />
                                            <div class="searchBoxFaq">
                                                {
                                                    faqAllData.map((item) => {
                                                        const { question, answer } = item;
                                                        return (
                                                            <>
                                                                <div class="box">
                                                                    <h4 dangerouslySetInnerHTML={{ __html: question }} />
                                                                    <div class="text">
                                                                        <p dangerouslySetInnerHTML={{ __html: answer }} />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </>
                                        : null
                                    }
                                    {AllData.length ? <>
                                        <h1>Services</h1>
                                        <hr />
                                        <div class="wcu wcuPage mt-5">
                                            <div class="container">
                                                <div class="row">
                                                    {
                                                        AllData.map((item) => {
                                                            const { icon, title, shortdescription } = item;
                                                            return (
                                                                <>
                                                                    <div class="col-md-6 col-lg-4" data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">
                                                                        <div class="box">
                                                                            <figure><img src={icon} alt="services-img" /></figure>
                                                                            <figcaption><h1 dangerouslySetInnerHTML={{ __html: title }} /><p dangerouslySetInnerHTML={{ __html: shortdescription }} /></figcaption>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </> :
                                        null
                                    }
                                </div>
                            </> :
                            <h1>No Data Found</h1>
                    }

                </div>
            </section>
        </>
    )
}

export default Search