import ReCAPTCHA from "react-google-recaptcha";
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel2';
import AOS from 'aos';

function Home() {

    const pageTitle = 'Home'
    document.title = pageTitle


    const [abtData, setAbtdata] = useState([]);
    const [bannerSlider, setBannerSlider] = useState([]);
    const [headerData, setHeaderData] = useState([]);
    const [cat, setCat] = useState([]);
    const [gernalData, setGernalData] = useState([]);
    const [services, setServices] = useState([]);
    const [clients, setClients] = useState([]);
    const [review, setReview] = useState([]);
    const [color,setColor]=useState('#000');
    const [tit,setTit]=useState('Disabled');
    const [verified,setVerified]=useState(false)
    const [dis,setDis]=useState("not-allowed")



    let cati = 300;

    const optionsBanner = {
        items:1,
        loop:true,
        autoplay:true,
        dots:true
        }
    const optionsPartners = {
        items:3,
		loop:true,
		autoplay:true,
        autoplayTimeout:1000,
		responsiveClass:true,
		slideSpeed : 200,
		paginationSpeed : 800,
    rewindSpeed : 1000,
		dots:false,
		responsive:{
			1080:{items:6},
			768:{items:3},
			600:{items:2},
			425:{items:1},
			360:{items:1},
			30:{items:1}
			
		}
    }
        const optionsReview = {
            items:2,
		loop:true,
		autoplay:true,
		responsiveClass:true,
		slideSpeed : 200,
		paginationSpeed : 800,
    rewindSpeed : 1000,
		dots:false,
        nav:false,
        navText:['<i class="fas fa-angle-left"></i>','<i class="fas fa-angle-right"></i>'],
		responsive:{
			980:{items:3},
			768:{items:2},
			600:{items:1},
			425:{items:1},
			360:{items:1},
			30:{items:1}
			
		}
    }

        useEffect(() => {
            AOS.init({easing: 'ease-in-out-sine',once:true})
 
            
            async function abtApi() {
                let response = await fetch(
                    'https://skelectronics.com.au/admin/api/Pages',
                    {
                        method: 'GET',
                    }
                );
                let data = await response.json(); // Extracting data as a JSON Object from the response
                setAbtdata(data)
            }
            abtApi();

            async function homeBannerSliderApi() {
                let response = await fetch(
                    'https://skelectronics.com.au/admin/api/slider',
                    {
                        method: 'GET',
                    }
                );
                let data = await response.json(); // Extracting data as a JSON Object from the response
                setBannerSlider(data)
            }
            homeBannerSliderApi();

            async function getResponse() {
                let response = await fetch(
                    'https://skelectronics.com.au/admin/api/GeneralData',
                    {
                        method: 'GET',
                    }
                );
                let data = await response.json(); // Extracting data as a JSON Object from the response
                setHeaderData(data)
            }
            getResponse()
            


            async function homeBannerSliderbannerBelowCategoryApi() {
                let response = await fetch(
                    'https://skelectronics.com.au/admin/api/Sliderservices',
                    {
                        method: 'GET',
                    }
                );
                let data = await response.json(); // Extracting data as a JSON Object from the response
                setCat(data)
            }
            homeBannerSliderbannerBelowCategoryApi();

            async function gernalData() {
                let response = await fetch(
                    'https://skelectronics.com.au/admin/api/GeneralData',
                    {
                        method: 'GET',
                    }
                );
                let data = await response.json(); // Extracting data as a JSON Object from the response
                setGernalData(data)
            }
            gernalData();

            async function servicesApi() {
                let response = await fetch(
                    'https://skelectronics.com.au/admin/api/services',
                    {
                        method: 'GET',
                    }
                );
                let data = await response.json(); // Extracting data as a JSON Object from the response
                setServices(data)
            }
            servicesApi();


            async function clientsApi() {
                let response = await fetch(
                    'https://skelectronics.com.au/admin/api/Clients',
                    {
                        method: 'GET',
                    }
                );
                let data = await response.json(); // Extracting data as a JSON Object from the response
                setClients(data)
            }
            clientsApi();




            async function reviewApi() {
                let response = await fetch(
                    'https://skelectronics.com.au/admin/api/Reviews',
                    {
                        method: 'GET',
                    }
                );
                let data = await response.json(); // Extracting data as a JSON Object from the response
                setReview(data)
            }
            reviewApi();

          }, [])
          function onChange(value) {
            setVerified(true);
            setColor("#001e4d");
            setTit("Send");
            setDis("pointer");
          }
          
          

  return (
    <>
    <section className="slider">
    {/* <div className="owl-carousel slider-carousel owl-theme"> */}
    <OwlCarousel options={optionsBanner}>
      
    {
        bannerSlider.data ? 
            bannerSlider.data.map((v,i)=>{
                return(
            <div className="item" key={i}>
                <img className="imgBanner" src={v.image} alt={v.image_alt} />
                <div className="item-content text-top" data-aos="fade-right" data-aos-duration="700" data-aos-delay="300">
                    <div className="container">
                        <div className="content-box">
                            <div className="contentBox">
                            <div dangerouslySetInnerHTML={{__html: v.description}} />
                            
                                {/* <Link to="/" className="btn">Read More</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                )
              
            }) : 'Location ...'
        
    }
    </OwlCarousel>
    <div class="wuc-slider">
        <div class="container">
           <div class="wuc-slider-box">
                <h2>Why Choose Us : </h2>
                <marquee loop><span>35 years of experience</span><span>Save big $$$ by giving second life to your device</span><span>Reduce your environmental impact</span><span>Expert insurance assessment reports for Accidental Demages</span></marquee>
            </div>
        </div>
    </div>
</section>
<section className="services">
    <div className="container">
        <div className="flex">
            { 
                cat.data && cat.data.map((v,i)=>{
                    return(
                        <div key={i} className="box" data-aos="fade-up" data-aos-duration="700" data-aos-delay={cati * i}>
                            <img src={v.image} alt={v.image_alt} />
                            <h3>{v.title}</h3>
                        </div>
                    )
                })
            }
            
        </div>
    </div>
</section>
<section className="wcu">
    <div className="container">
        <div className="headingBox" data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">
            
        {
         gernalData.data ? gernalData.data.map((v,i)=>{
            if(v.id === "13"){
                return(
                    <div dangerouslySetInnerHTML={{__html: v.description}} />
                )
            }else{
                return(
                    <></>
                )
            }
         }) : 'Loading...'
        }
        </div>
        <div className="row">
            {
                 services.data && services.data.map((v,i)=>{
                        return(
                            <div key={i} className="col-md-6 col-lg-4" data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">
                <div className="box">
                    <figure><img src={v.icon} alt={v.image_alt} /></figure>
                    {/* <figcaption><h1>{v.title}</h1><p>{v.shortdescription}</p></figcaption> */}
                    <figcaption><h1>{v.title}</h1><div dangerouslySetInnerHTML={{__html: v.shortdescription}} /></figcaption>
                </div>
            </div>
                        )
                 })   
            }
        </div>
    </div>
</section>
<section className="auth">
    <div className="container">
        {
         gernalData.data && gernalData.data.map((v,i)=>{
            if(v.id === "14"){
                return(
                    <>
                        <h1 dangerouslySetInnerHTML={{__html: v.description}}></h1>
                        <div className="flex jcc">
                            <div className="box">
                                <img src={v.image} alt={v.image_alt} />
                            </div>
                        </div>
                    </>
                )
            }else{
                return(
                    <></>
                )
            }
         })   
        }
    </div>
</section>
<section className="abt">
    <div className="container">
    {
                        abtData.data ? abtData.data.map((v,i)=>{
                            if(v.id === "11"){
                                return(
        <div className="row aic">
            <div className="col-lg-7">
                <div className="rightContent">
                    
                                <>
                                <div dangerouslySetInnerHTML={{__html: v.description}} />
                                
                                </>
                                
                    <Link to="/about" className="btn">Read More</Link>
                </div>
            </div>
            <div className="col-lg-5">
                <aside>
                    <figure data-aos="fade-left" data-aos-duration="700" data-aos-delay="300"><img src={v.image} alt="" /></figure>
                    <img src="https://skelectronics.com.au/assets/images/more/abt2.jpg" className="img2" alt="about" />
                </aside>
            </div>
        </div>
        )
    }else{
        return(
            <></>
        )
    }
}) : 'Loading...'
}
    </div>
</section>
<section className="expertise">
    <div className="container">
       <h1>OUR EXPERTISE = We Fix All   </h1>
        <div className="row">
        {
         gernalData.data && gernalData.data.map((v,i)=>{
            if(v.id === "15"){
                return(
                    <>
                        
                        <div className="col-lg-6">
                <div className="imgBox"><img src={v.image} alt={v.image_alt} /></div>
            </div>
            <div className="col-lg-6">
            <aside dangerouslySetInnerHTML={{__html: v.description}}></aside>
            </div>
                    </>
                )
            }else{
                return(
                    <></>
                )
            }
         })   
        }
        
        </div>
    </div>
</section>
<section className="partners">
    <div className="container">
        <h1>We also welcome trade inquires & repairs for..</h1>
        {/* <div className="owl-carousel partners-carousel owl-theme"> */}
        <OwlCarousel options={optionsPartners}>
        {
            clients.data ?
                  clients.data.map((v,i)=>{
                        return(
                            <div className="item" key={i}>
                                <figure>
                                    <img src={v.image} alt={v.image_alt} />
                                </figure>
                            </div>
                        )
                 })   : 'Loading...'
            }
            
            </OwlCarousel>
    </div>
</section>
<section className="review">
    <div className="container">
        <h1>We provide on-site repairs and installation in</h1>
        <div className="row">
            <div className="col-md-12 col-lg-3">
                <div className="leftBox">
                    <img src="https://skelectronics.com.au/assets/images/more/google-review.png" alt="" className="comLogo" />
                    <div className="rating">
                        <p>4.7</p>
                        <ul>
                            <li><i className="fas fa-star"></i></li>
                            <li><i className="fas fa-star"></i></li>
                            <li><i className="fas fa-star"></i></li>
                            <li><i className="fas fa-star"></i></li>
                            <li><i className="fas fa-star"></i></li>
                        </ul>
                    </div>
                    <p>Based on 92 reviews
powerd by <img src="/assets/images/icons/google.png" alt="" /></p>
               <Link to="#" className="btn"><span>reviews us on</span> <img src="https://skelectronics.com.au/assets/images/icons/g.png" alt="" /></Link>
                </div>
            </div>
            <div className="col-md-12  col-lg-9">
                <div className="rightBox">
                    {/* <div className="owl-carousel review-carousel owl-theme"> */}
                    <OwlCarousel options={optionsReview}>
                    {
                        review.data ? review.data.map((v,i)=>{
                            return(
                                <div key={i} className="item">
                            <div className="box">
                                <div className="flex">
                                    <div className="icon">{v.name.charAt(0)}</div>
                                    <div className="text">
                                        <h5>{v.name}</h5>
                                        <h6>{v.timeago}</h6>
                                    </div>
                                </div>
                                <div className="rating">
                                    <ul>
                                        <li><i className="fas fa-star"></i></li>
                                        <li><i className="fas fa-star"></i></li>
                                        <li><i className="fas fa-star"></i></li>
                                        <li><i className="fas fa-star"></i></li>
                                        <li><i className="fas fa-star"></i></li>
                                    </ul>
                                </div>
                                <div className="content">
                                <div dangerouslySetInnerHTML={{__html: v.description}} />
                                </div>
                            </div>
                        </div>
                            )
                        }) : 'Loading...'
                    }
                        
                        </OwlCarousel>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="con">
    <div class="container">
        <div class="row">
            <div class="col-lg-5">
                <h1>Contact Us :</h1>
                <div class="info">
                    <ul>
                    {
                                headerData.data ? headerData.data.map((v, i) => {
                                    // if (v.id === "20") {
                                    //     return (
                                    //         <>
                                    //             <li>
                                    //                 <i class="fas fa-map-marker-alt"></i>
                                    //                 <Link to={v.description} target='_blank'> {v.title}</Link>
                                    //             </li>
                                    //         </>
                                    //     )
                                    // } else
                                        if (v.id === "3") {
                                            return (
                                                <>
                                                    <li>
                                                        <div class="icon">
                                                            <i class="fas fa-phone-alt"></i>
                                                        </div>
                                                        <div className='content'>
                                                            <b for="">Call anytime</b>
                                                            <Link to={"tel:" + v.description}> {v.description}</Link>
                                                            <Link to="tel:1300 349 255">1300 349 255 ( 1300 FIX ALL )</Link>
                                                        </div>
                                                    </li>
                                                </>
                                            )
                                        } 
                                        else if (v.id === "2") {
                                                return (
                                                    <>
                                                    <li>
                                                        <div class="icon">
                                                            <i class="fas fa-envelope"></i>
                                                        </div>
                                                        <div className='content'>
                                                            <b for="">Send email</b>
                                                            <Link to={"mailto:" + v.description}> {v.description}</Link>
                                                        </div>
                                                    </li>
                                                    </>
                                                )
                                            } else if (v.id === "20") {
                                                return (
                                                    <>
                                                        <li>
                                                        <div class="icon">
                                                            <i class="fas fa-map-marker-alt"></i>
                                                        </div>
                                                        <div className='content'>
                                                            <b for="">Address</b>
                                                            <Link to={v.description} target='_blank'> {v.title}</Link>
                                                        </div>
                                                        </li>
                                                    </>
                                                )
                                            } else {
                                                return (
                                                    <></>
                                                )
                                            }
                                }) : 'Loading...'
                            }
                    </ul>
                </div>
                <div class="moreInfo info">
                    <ul>
                       <li>
                            <div class="icon">
                                <i class="far fa-clock"></i>
                            </div>  
                            <div class="content">
                                <b>Hours</b>
                                <Link to="#"><span>Mon - Fri   </span> <span>9:00 am - 5:00 pm</span></Link>
                                <Link to="#"><span>Sat - Sun  </span> <span>Closed</span></Link>
                            </div>
                        </li>
                        
                    </ul>
                </div>
            </div>
            <div class="col-lg-7">
                <div class="formBox">
                    <h1>Drop by to Chat</h1>
                    <form action="./mail2.php" method='post' enctype="multipart/form-data">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="grp">
                                    <input type="text" placeholder="Name" name="name" minLength={5} required/>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="grp">
                                    <input type="email" placeholder="Email" name="email" minLength={9} required/>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="grp">
                                    <input type="text" placeholder="Phone" name="phone" minLength={5} required/>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="grp">
                                    <input type="text" placeholder="Modal :" name="modal" minLength={5} required/>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="grp">
                                    <input type="text" placeholder="Purchase Year" name="purchase" minLength={5} required/>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                    <div class="grp">
                                    <textarea placeholder="Brief Fault Description / Service Requirement" name="msg" minLength={5} required></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="flex grp">
                                    <div class="box mr-5">
<div class="custom-control custom-radio">
    <input type="radio" class="custom-control-input" id="customRadio1" name="example1" value="Call Out" />
    <label class="custom-control-label" for="customRadio1">Call Out</label>
</div>
                                    </div>
                                    <div class="box">
<div class="custom-control custom-radio">
    <input type="radio" class="custom-control-input" id="customRadio2" name="example1" value="Carry In" />
    <label class="custom-control-label" for="customRadio2">Carry In</label>
</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="grp">
<div class="custom-file">
    <input type="file" class="custom-file-input" id="customFile" name="file" />
    <label class="custom-file-label" for="customFile">Choose file</label>
  </div>
                                </div>
                            </div>
                            <div className='col-md-12'>
                             <ReCAPTCHA
                                sitekey="6LeBhZQpAAAAAHwT8yTl5qlMqDYeAyRfABNCb1aV"
                                // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                onChange={onChange}
                              />
                            </div>
                            <div class="col-md-12">
                                <div className='grp'>
                                <input type="submit" value="send"  name="submitBtn" style={{background:color,cursor:dis}} title={tit} disabled={!verified} />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="map">
    <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3140.363163246936!2d145.24464257565475!3d-38.085209771908765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad60e18118e1257%3A0xcc61e514cc0c47d8!2su13%2F37%20Industrial%20Cct%2C%20Cranbourne%20West%20VIC%203977%2C%20Australia!5e0!3m2!1sen!2sin!4v1686644772928!5m2!1sen!2sin" width="600" height="450" allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</section>
    </>
  )
}

export default Home