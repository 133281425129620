import React from 'react'
import { Link } from 'react-router-dom'

function Banner(props) {
    console.log(props);
  return (
    <section className="banner">
        <img src={props.imgPath} alt="" />
            <div className="bannerBox">
                <div className="container">
                    <h1>{props.title}</h1>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="">{props.title}</Link></li>
                    </ul>
                </div>
            </div>
        </section>
  )
}

export default Banner