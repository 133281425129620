import React, { useEffect, useState } from 'react'
import AOS from 'aos';
import Banner from './Banner';
import { Link, useParams } from 'react-router-dom';

function Services({ props }) {
    const pageTitle = 'Services'
    document.title = pageTitle

    const [services, setServices] = useState([]);
    const [abtData, setAbtdata] = useState([]);

    // const param = useParams();
    // console.log(props)
    useEffect(() => {
        AOS.init({ easing: 'ease-in-out-sine', once: true })


        async function servicesApi() {
            let response = await fetch(
                'https://skelectronics.com.au/admin/api/services',
                {
                    method: 'GET',
                }
            );
            let data = await response.json(); // Extracting data as a JSON Object from the response
            setServices(data)
        }
        servicesApi();
        async function abtApi() {
            let response = await fetch(
                'https://skelectronics.com.au/admin/api/Pages',
                {
                    method: 'GET',
                }
            );
            let data = await response.json(); // Extracting data as a JSON Object from the response
            setAbtdata(data)
        }
        abtApi();
    }, [])

    return (
        <>
            {
                abtData.data ? abtData.data.map((v, i) => {
                    if (v.id === "12") {
                        return (<Banner title={v.title} imgPath={v.banner} />)
                    } else {
                        return (
                            <></>
                        )
                    }
                }) : 'Loading...'
            }
            <section className="wcu wcuPage">
                <div className="container">
                    <div className="row">
                        {
                            services.data && services.data.map((v, i) => {
                                // if (v.shortdescription.includes(props)) {
                                //     return console.log(v.shortdescription)
                                // }
                                // console.log(v.shortdescription);
                                return (
                                    <div key={i} className="col-md-6 col-lg-4" data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">
                                        <div className="box"><Link to={v.slug}>
                                            <figure><img src={v.icon} alt={v.image_alt} /></figure>
                                            <figcaption><h1 dangerouslySetInnerHTML={{ __html: v.title }} /><p dangerouslySetInnerHTML={{ __html: v.shortdescription }} /></figcaption></Link>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default Services