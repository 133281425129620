import React, { useEffect, useState } from 'react'
import Banner from './Banner'
import AOS from 'aos';

function Faq() {
    const pageTitle = 'FAQ'
    document.title = pageTitle

    const [data, setData] = useState([]);
    const [abtData, setAbtdata] = useState([]);


    useEffect(() => {
        AOS.init({ easing: 'ease-in-out-sine', once: true })

        async function faqApi() {
            let response = await fetch(
                'https://skelectronics.com.au/admin/api/faqs',
                {
                    method: 'GET',
                }
            );
            let data = await response.json(); // Extracting data as a JSON Object from the response
            setData(data)

            async function abtApi() {
                let response = await fetch(
                    'https://skelectronics.com.au/admin/api/Pages',
                    {
                        method: 'GET',
                    }
                );
                let data = await response.json(); // Extracting data as a JSON Object from the response
                setAbtdata(data)
            }
            abtApi();
        }
        faqApi();



    }, [])

    return (
        <>
             {
        abtData.data ? abtData.data.map((v,i)=>{
            if(v.id === "14"){
                return(<Banner title={v.title} imgPath={v.banner} />)
            }else{
                return(
                    <></>
                )
            }
        }) : 'Loading...'
    }
            <section className="faq">
                <div className="container">
                    <h1>Frequently Asked Questions</h1>
                    <div id="accordion">
                        {/* <div class="card">
                            <div class="card-header">
                                <a class="card-link" data-toggle="collapse" href="#collapse1">
                                    What is Lorem Ipsum?
                                </a>
                                <i class="fas fa-minus"></i>
                            </div>
                            <div id="collapse1" class="collapse show" data-parent="#accordion">
                                <div class="card-body">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.  The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum .</p>
                                </div>
                            </div>
                        </div> */}
                        {
                            data.data ? data.data.map((v, i) => {
                                return (
                                    <div class="card">
                                        <div class="card-header">
                                            <a class="collapsed card-link" data-toggle="collapse" href={"#collapse" + (i + 2)}>
                                                {v.question}
                                            </a>
                                            <i class="fa-solid fa-plus"></i>
                                        </div>
                                        <div id={"collapse" + (i + 2)} class="collapse " data-parent="#accordion">
                                            <div class="card-body" dangerouslySetInnerHTML={{ __html: v.answer }} />
                                        </div>
                                    </div>
                                )


                            }) : 'Loading...'
                        }
                    </div>
                    {
                        data.data ? data.data.map((v, i) => {
                            if (v.id === "11") {
                                return (
                                    <div className="row aic">
                                        <div className="col-md-7">
                                            <div className="rightContent">

                                                <>
                                                    <div dangerouslySetInnerHTML={{ __html: v.description }} />

                                                </>

                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <aside>
                                                <figure data-aos="fade-left" data-aos-duration="700" data-aos-delay="300"><img src={v.image} alt="" /></figure>
                                                <img src="/assets/images/more/abt2.jpg" alt='about' className="img2" />
                                            </aside>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <></>
                                )
                            }
                        }) : 'Loading...'
                    }
                </div>
            </section>
        </>
    )
}

export default Faq