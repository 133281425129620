import React from 'react'
import { Link } from 'react-router-dom'
const ServiceArea = () => {
  return (
    <>
      <section class='sk-map'>
        <figure>
          <img className='tni' src='./assets/images/more/sk-map.png' alt='' />
        </figure>
        <div className='autho'>
            <div className='container'>
                <figure>
                  <img className='tni' src='./assets/images/more/sk-map-bottom.png' alt='' />
                </figure>
                <div className='states'>
                    <ul>
                        <div className='box box1'>
                            <li>Shire of Cardinia</li>
                            <li>Avonsleigh 3782</li>
                            <li>Bayles 3981</li>
                            <li>Beaconsfield 3807</li>
                            <li>Beaconsfield Upper 3808</li>
                            <li>Beenak</li>
                            <li>Bunyip 3815</li>
                            <li>Caldermeade 3984</li>
                            <li>Cardinia 3978</li>
                            <li>Catani 3981</li>
                            <li>Clematis 3782</li>
                            <li>Cockatoo 3781</li>
                            <li>Cora Lynn 3814</li>
                            <li>Dalmore 3981</li>
                            <li>Dewhurst</li>
                            <li>Emerald 3782</li>
                            <li>Garfield 3814</li>
                            <li>Gembrook 3783</li>
                            <li>Guys Hill 3807</li>
                            <li>Iona 3815</li>
                            <li>Koo Wee Rup 3981</li>
                            <li>Lang Lang 3984</li>
                            <li>Maryknoll 3812</li>
                            <li>Menzies Creek 3159</li>
                            <li>Modella 3816</li>
                            <li>Monomeith 3984</li>
                            <li>Mount Burnett 3781</li>
                            <li>Nangana 3781</li>
                            <li>Nar Nar Goon 3812</li>
                            <li>Officer 3809</li>
                            <li>Pakenham 3810</li>
                            <li>Tenby Point 3984</li>
                            <li>Tonimbuk 3815</li>
                            <li>Toomuc Valley 3810</li>
                            <li>Tooradin North 3977</li>
                            <li>Tynong 3813</li>
                        </div>
                        <div className='box box2'>
                            <li>City of Casey</li>
                            <li> Berwick 3806</li>
                            <li> Blind Bight 3980</li>
                            <li> Cannons Creek 3977</li>
                            <li> Clyde 3978</li>
                            <li> Clyde North 3978</li>
                            <li> Cranbourne 3977</li>
                            <li> Cranbourne East 3977</li>
                            <li> Cranbourne North 3977</li>
                            <li> Cranbourne South 3977</li>
                            <li> Cranbourne West 3977</li>
                            <li> Devon Meadows 3977</li>
                            <li> Doveton 3177</li>
                            <li> Endeavour Hills 3802</li>
                            <li> Eumemmerring 3177</li>
                            <li> Five Ways 3977</li>
                            <li> Hallam 3803</li>
                            <li> Hampton Park 3976</li>
                            <li> Harkaway 3806</li>
                            <li> Junction Village 3977</li>
                            <li> Lynbrook 3975</li>
                            <li> Lysterfield South 3156</li>
                            <li> Narre Warren 3805</li>
                            <li> Narre Warren North 3804</li>
                            <li> Narre Warren South 3805</li>
                            <li> Pearcedale 3912</li>
                            <li> Tooradin 3980</li>
                            <li> Warneet 3980</li>
                        </div>
                        <div className='box box3'>
                            <li>City of Greater Dandenong</li>
                            <li>Bangholme 3175</li>
                            <li>Dandenong 3175</li>
                            <li>Dandenong North 3175</li>
                            <li>Dandenong South 3175</li>
                            <li>Keysborough 3173</li>
                            <li>Lyndhurst 3975</li>
                            <li>Noble Park 3174</li>
                            <li>Noble Park North 3174</li>
                            <li>Springvale 3171</li>
                            <li>Springvale South 3172</li>
                        </div>
                        <div className='box box4'>
                            <li>City of Frankston</li>
                            <li> Carrum Downs 3201</li>
                            <li> Frankston 3199</li>
                            <li> Karingal</li>
                            <li> Frankston North 3200</li>
                            <li> Frankston South 3199</li>
                            <li> Langwarrin 3910</li>
                            <li> Skye 3977</li>
                            <li> Seaford 3198</li>
                            <li> Kananook</li>
                        </div>
                        <div className='box box5'>
                            <li>City of Kingston</li>
                            <li> Aspendale 3195</li>
                            <li> Aspendale Gardens 3195</li>
                            <li> Bonbeach 3197</li>
                            <li> Braeside 3195</li>
                            <li> Carrum 3197</li>
                            <li> Chelsea 3196</li>
                            <li> Chelsea Heights 3196</li>
                            <li> Cheltenham (Shared with City of Bayside) 3192</li>
                            <li> Clarinda 3169</li>
                            <li> Clayton South 3169</li>
                            <li> Dingley Village 3172</li>
                            <li> Edithvale 3196</li>
                            <li> Heatherton 3202</li>
                            <li> Highett (Shared with City of Bayside) 3190</li>
                            <li> Mentone 3194</li>
                            <li> Moorabbin 3189</li>
                            <li> Moorabbin Airport 3194</li>
                            <li> Mordialloc 3195</li>
                            <li> Parkdale 3194</li>
                            <li> Patterson Lakes 3197</li>
                            <li> Waterways 3195</li>
                        </div>
                        <div className='box box6'>
                            <li>City of Monash</li>
                            <li> Ashwood 3147</li>
                            <li> Chadstone 3148</li>
                            <li> Clayton 3168</li>
                            <li> Glen Waverley 3150</li>
                            <li> Hughesdale 3166</li>
                            <li> Huntingdale 3166</li>
                            <li> Monash University 3800</li>
                            <li> Mount Waverley 3149</li>
                            <li> Mulgrave 3170</li>
                            <li> Notting Hill 3168</li>
                            <li> Oakleigh 3166</li>
                            <li> Oakleigh East 3166</li>
                            <li> Oakleigh South 3167</li>
                            <li> Wheelers Hill 3150</li>

                        </div>
                        <div className='box box7'>
                            <li>Shire of Mornington Peninsula</li>
                            <li> Arthurs Seat 3936</li>
                            <li> Balnarring 3926</li>
                            <li> Balnarring Beach 3926</li>
                            <li> Baxter 3911</li>
                            <li> Bittern 3918</li>
                            <li> Blairgowrie 3942</li>
                            <li> Boneo 3939</li>
                            <li> Cape Schanck 3939</li>
                            <li> Crib Point 3919</li>
                            <li> Dromana 3936</li>
                            <li> Flinders 3929</li>
                            <li> Hastings 3915</li>
                            <li> HMAS Cerberus 3920</li>
                            <li> Main Ridge 3928</li>
                            <li> Merricks 3916</li>
                            <li> Merricks Beach 3926</li>
                            <li> Merricks North 3926</li>
                            <li> Moorooduc 3933</li>
                            <li> Mornington 3931</li>
                            <li> Mount Eliza 3930</li>
                            <li> Mount Martha 3934</li>
                            <li> Point Leo 3916</li>
                            <li> Portsea 3944</li>
                            <li> Red Hill 3059</li>
                            <li> Red Hill South 3937</li>
                            <li> Rosebud 3939</li>
                            <li> Rosebud West 3940</li>
                            <li> Rye 3941</li>
                            <li> Safety Beach 3936</li>
                            <li> Shoreham 3916</li>
                            <li> Somers 3927</li>
                            <li> Somerville 3912</li>
                            <li> Sorrento 3943</li>
                            <li> Tootgarook 3</li>
                        </div>
                        <div className='box box8'>
                            <li><Link to="/" target='_blank'>www.skelectronics.com.au</Link></li>
                            <li><Link to="https://maps.app.goo.gl/TW81e5eVJXggseNL6" target='_blank'>Google Map Directions</Link></li>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default ServiceArea
