import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./pages/Header";
import Home from "./pages/Home";
import Footer from "./pages/Footer";
import About from "./pages/About";
import Services from "./pages/Services";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
import ServiceDetails from "./pages/ServicesDetail";
import Test from "./pages/Test";
import Search from "./pages/Search";
import Error from "./pages/Error";
import ServiceArea from "./pages/ServiceArea";


function App() {
  
 // basename={'/design/skelectronics/dev'}

  return (
    <>
    {/* <BrowserRouter basename={'https://skelectronics.com.au'}> */}
    <BrowserRouter>
      <Header /> 
      <Routes>
          <Route exact  path="/" element={<Home/>} />
          <Route exact  path="/services" element={<Services/>} />
          <Route exact  path="/about" element={<About/>} />
          <Route exact  path="/services" element={<Services/>} />
          <Route exact  path="/services/:id" element={<ServiceDetails/>} />
          <Route exact  path="/contact" element={<Contact/>} />
          <Route exact  path="/faq" element={<Faq/>} />
          <Route exact  path="/test" element={<Test/>} />
          <Route exact  path="/ser" element={<ServiceArea/>} />
          <Route exact  path="*" element={<Error/>} />
          <Route exact  path="/search/:id" element={<Search/>} />
      </Routes>
      <Footer/>
    </BrowserRouter>
    </>
  );
}

export default App;
