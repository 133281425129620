import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'

function Header() {
    const [headerData, setHeaderData] = useState([]);
    const [searchVal, setSearchVal] = useState('');
    const [faq, setFaq] = useState('');
    // console.log(faq);
    // console.log(searchVal);
    const navigate = useNavigate();
    useEffect(() => {



        async function getResponse() {
            let response = await fetch(
                'https://skelectronics.com.au/admin/api/GeneralData',
                {
                    method: 'GET',
                }
            );
            let data = await response.json(); // Extracting data as a JSON Object from the response
            setHeaderData(data)
        }
        getResponse()
        async function faq() {
            let response = await fetch(
                'https://skelectronics.com.au/admin/api/faqs',
                {
                    method: 'GET',
                }
            );
            let data = await response.json(); // Extracting data as a JSON Object from the response
            setFaq(data)
        }
        faq()
    }, [])
    //console.warn(faq.data)


    const searchFilter = (items) => {
        // alert(searchVal)
        navigate("/search/" + searchVal)
        // window.location.href("/search:" + searchVal)
        const abc = faq.data.filter((item) => {

            let addData = [];
            if (item.question.includes(searchVal)) {
                // return item.question.includes(searchVal);
                return item.question

                // navigate("/search:" + searchVal)
                // window.location.href("/search:" + searchVal)
                //addData.push(item.question.includes(searchVal))

            }
            else if (item.answer.includes(searchVal)) {
                // return item.answer.includes(searchVal)
                return item.answer
                //addData.push(item.answer.includes(searchVal))
            } else {
                return null;
                console.log("no data found");
            }
            // console.warn(addData)

        })
        abc.map((i) => {
            // console.warn('cc', i.id)
            // console.log(i);
        })
        //console.warn('abc',abc)


    }



    return (
        <header>
            <div className="navtop">
                <div className="container">
                    <div className="uppernav">
                        <div className="left-info">
                            {
                                headerData.data ? headerData.data.map((v, i) => {
                                    if (v.id === "1") {
                                        return (
                                            <>
                                                {v.description}
                                            </>
                                        )
                                    } else {
                                        return (
                                            <></>
                                        )
                                    }
                                }) : 'Loading...'
                            }
                        </div>
                        <div className="right-info">
                            <ul>

                                {
                                    headerData.data ? headerData.data.map((v, i) => {
                                        if (v.id === "20") {
                                            return (
                                                <>
                                                    <li>
                                                        <i class="fas fa-map-marker-alt"></i>
                                                        <Link to={v.description} target='_blank'> Direction</Link>
                                                    </li>
                                                </>
                                            )
                                        } else
                                            if (v.id === "3") {
                                                return (
                                                    <>
                                                        <li>
                                                            <i class="fas fa-phone-alt"></i>
                                                            <Link to={"tel:" + v.description}> Call</Link>
                                                        </li>
                                                    </>
                                                )
                                            } else
                                                if (v.id === "2") {
                                                    return (
                                                        <>
                                                            <li className="order-1">
                                                                <i className="fas fa-envelope"></i>
                                                                <Link to={"mailto:" + v.description}> {v.title}</Link>
                                                            </li>
                                                        </>
                                                    )
                                                } else if (v.id === "21") {
                                                    return (
                                                        <>
                                                            <li className="order-2">
                                                                <i className="fas fa-phone-alt"></i>
                                                                <Link to={"tel:" + v.description}> {v.description}</Link>
                                                            </li>
                                                        </>
                                                    )
                                                } else if (v.id === "11") {
                                                    return (
                                                        <>
                                                            <li className="order-3 mr-0 ml-3">
                                                                <form onSubmit={(e) => { searchFilter(e); e.preventDefault() }} action='/search'>
                                                                    <input type="search" name="search" value={searchVal} onChange={(e) => setSearchVal(e.target.value)} placeholder="Search here..." />
                                                                    <button type="submit" class=""><i class="fas fa-search"></i></button>
                                                                </form>
                                                            </li>
                                                        </>
                                                    )
                                                } else {
                                                    return (
                                                        <></>
                                                    )
                                                }
                                    }) : 'Loading...'
                                }

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-nav">
                <div className="container">
                    <div className="flex">
                        <div className="logo navbar-light">
                            <Link to="/">
                                {
                                    headerData.data ? headerData.data.map((v, i) => {
                                        if (v.id === "5") {
                                            return (
                                                <>
                                                    <img src={v.image} alt={v.description} />
                                                </>
                                            )
                                        } else {
                                            return (
                                                <></>
                                            )
                                        }
                                    }) : 'Loading...'
                                }
                            </Link>
                            <button className="navbar-toggler">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                        <nav>
                            <div className="closeBox">
                                <span className="close-btn"><i className="fa-2x fa-regular fa-rectangle-xmark"></i></span>
                            </div>
                            <div className="navigation">
                                <ul>
                                    <li><NavLink exact={true} activeClassName="active" to="/">Home</NavLink></li>
                                    <li><NavLink activeClassName="active" to="/about">About Us</NavLink></li>
                                    <li><NavLink activeClassName="active" to="/services">Services</NavLink></li>
                                    <li><NavLink activeClassName="active" to="/faq">Faq</NavLink></li>
                                    <li><NavLink activeClassName="active" to="/contact">Contact Us</NavLink></li>
                                    <li><NavLink activeClassName="active" to="/ser">service area</NavLink></li>
                                </ul>
                            </div>
                            <div className="more">
                                <Link to="/contact" className="btn">Get A Quote</Link>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header