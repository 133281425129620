import React from 'react';
import { Link } from 'react-router-dom';
const Error404 = () => {
    return (
        <>
            <section class="inner-section">
                <div class="about-section terms text-center">
                    <div class="container">
                        <div class="box-404">
                            <h1 class="bigheading"><span>4</span><b>0</b><span>4</span></h1>
                        </div>
                        <h2>Oops... It looks like you ‘re lost !</h2>
                        <p>The page you were looking for dosen’t exist.</p>
                        <div class="back-btn text-center">
                            <Link to="/" class="btn"><img src="./assets/images/icons/arrow.png" alt="arrow" />Go back</Link>
                        </div>
                    </div>
                </div>
            </section></>
    )
}

export default Error404